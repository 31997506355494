<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Empresa pagadora"
            label-for="payingCompany"
          >
            <b-form-input
              id="payingCompany"
              v-model="payingCompany"
              name="payingCompany"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="RUC"
            label-for="ruc"
          >
            <b-form-input
              id="ruc"
              v-model="ruc"
              name="ruc"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Tipo de documento"
            label-for="documentType"
          >
            <b-form-input
              id="documentType"
              v-model="documentType"
              name="documentType"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Moneda"
            label-for="currency"
          >
            <b-form-input
              id="currency"
              v-model="currency"
              name="currency"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Fecha de recepción"
            label-for="receptionDate"
          >
            <b-form-input
              id="receptionDate"
              v-model="receptionDate"
              name="receptionDate"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Código de documento"
            label-for="documentNumber"
          >
            <b-form-input
              id="documentNumber"
              v-model="documentNumber"
              name="documentNumber"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Fecha de pago"
            label-for="payingDate"
          >
            <b-form-input
              id="payingDate"
              v-model="payingDate"
              name="payingDate"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Plazo"
            label-for="payingTerm"
          >
            <b-form-input
              id="payingTerm"
              v-model="payingTerm"
              name="payingTerm"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Importe original"
            label-for="originalAmount"
          >
            <b-form-input
              id="originalAmount"
              v-model="originalAmount"
              name="originalAmount"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Importe de pago"
            label-for="payingAmount"
          >
            <b-form-input
              id="payingAmount"
              v-model="payingAmount"
              name="payingAmount"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Cuotas"
            label-for="duesQuantity"
          >
            <b-form-input
              id="duesQuantity"
              v-model="duesQuantity"
              name="duesQuantity"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-for="(payingValue, index) in payingValues"
        :key="payingValue+index"
      >
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            :label="`Fecha de pago ${index + 1}`"
            :label-for="`payingDate${index}`"
          >
            <b-form-input
              :id="`payingDate${index}`"
              v-model="payingValue.payingDate"
              :name="`payingDate${index}`"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            :label="`Importe de pago ${index + 1}`"
            :label-for="`payingAmount${index}`"
          >
            <b-form-input
              :id="`payingAmount${index}`"
              v-model="payingValue.payingAmount"
              :name="`payingAmount${index}`"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          lg="12"
          sm="12"
        >
          <b-form-group
            label-cols-sm="12"
            label-cols-lg="12"
            content-cols-sm
            content-cols-lg="9"
            label="Observación"
            label-for="observation"
          >
            <b-form-textarea
              id="observation"
              v-model="observation"
              name="observation"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        class="justify-content-center my-2"
      >
        <b-col
          v-if="(xml !== '')"
          sm="6"
          md="3"
        >
          <b-button
            class="my-1"
            variant="outline-primary"
            :href="xml"
          >
            Descargar XML
          </b-button>
        </b-col>
        <b-col
          v-if="(frontalImage !== '')"
          sm="6"
          md="3"
        >
          <b-button
            class="my-1"
            variant="outline-primary"
            :href="frontalImage"
          >
            Descargar Imagen Frontal
          </b-button>
        </b-col>
        <b-col
          v-if="(backImage !== '')"
          sm="6"
          md="3"
        >
          <b-button
            class="my-1"
            variant="outline-primary"
            :href="backImage"
          >
            Descargar Imagen Posterior
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-row
      v-show="show"
      class="justify-content-center my-2"
    >
      <b-col
        v-if="requestId"
        sm="6"
        md="3"
      >
        <!-- return button -->
        <b-button
          class="my-1"
          variant="outline-secondary"
          :to="{ name:'Admin-receipts-list', params:{ id: requestId } }"
          block
        >
          Volver
        </b-button>
      </b-col>
      <b-col
        sm="6"
        md="3"
      >
        <!-- Reject button -->
        <b-button
          v-b-modal.modal-reject
          class="my-1"
          variant="secondary"
          block
        >
          Rechazar
        </b-button>
      </b-col>
      <b-col
        sm="6"
        md="3"
      >
        <!-- Approve button -->
        <b-button
          v-b-modal.modal-approve
          class="my-1"
          variant="primary"
          block
        >
          Aprobar
        </b-button>
      </b-col>
    </b-row>
    <b-row
      v-show="!show"
    >
      <b-col
        v-show="receiptStatus === 'rejected'"
        sm="12"
        md="12"
      >
        <b-alert
          variant="danger"
          show
        >
          <div class="alert-body">
            <span>El comprobante ha sido rechazado.</span>
          </div>
        </b-alert>
      </b-col>
      <b-col
        v-show="receiptStatus === 'approved'"
        sm="12"
        md="12"
      >
        <b-alert
          variant="success"
          show
        >
          <div class="alert-body">
            <span>El comprobante ha sido aceptado.</span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <!-- modal reject -->
    <b-modal
      id="modal-reject"
      centered
      title="Rechazar"
      cancel-variant="outline-secondary"
      cancel-title="Cancelar"
      ok-title="Guardar"
      @ok="handleReject"
    >
      <validation-observer ref="rejectForm">
        <b-form
          @submit.prevent="validateRejectForm"
        >
          <b-form-group
            label="Ingrese motivo de rechazo*"
            label-for="rejectMotive"
          >
            <validation-provider
              #default="{ errors }"
              name="Motivo de rechazo"
              rules="required"
              :vid="rejectMotive"
            >
              <b-form-textarea
                :id="rejectMotive"
                v-model="rejectMotive"
                :state="errors.length > 0 ? false:null"
                name="rejectMotive"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer="{ cancel, ok }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          variant="outline-secondary"
          @click="cancel()"
        >
          Cancelar
        </b-button>
        <b-button
          variant="primary"
          @click="ok()"
        >
          Guardar
        </b-button>
      </template>
    </b-modal>
    <!-- modal approve -->
    <b-modal
      id="modal-approve"
      centered
      title="Aprobar"
      cancel-variant="outline-secondary"
      cancel-title="Cancelar"
      ok-title="Guardar"
      @ok="handleApprove"
    >
      <validation-observer ref="approveForm">
        <b-form
          @submit.prevent="validateApproveForm"
        >
          <b-form-group
            label="Ingrese motivo de aprobación*"
            label-for="approveMotive"
          >
            <validation-provider
              #default="{ errors }"
              name="Motivo de rechazo"
              rules="required"
              :vid="approveMotive"
            >
              <b-form-textarea
                :id="approveMotive"
                v-model="approveMotive"
                :state="errors.length > 0 ? false:null"
                name="approveMotive"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer="{ cancel, ok }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          variant="outline-secondary"
          @click="cancel()"
        >
          Cancelar
        </b-button>
        <b-button
          variant="primary"
          @click="ok()"
        >
          Guardar
        </b-button>
      </template>
    </b-modal>
    <!-- confirm modal -->
    <b-modal
      id="modal-multi-3"
      ref="my-modal"
      centered
      size="sm"
      title="¿Desea conservar los cambios?"
      cancel-title="Cancelar"
      ok-title="Aceptar"
      @cancel="choiceCanceled"
      @ok="choiceConfirmed"
    >
      <b-card-text class="my-1">
        Recuerde que esta acción no es editable
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BButton, BForm, VBModal, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BAlert,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BForm,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      userEmail: JSON.parse(localStorage.getItem('userData')).email,
      // menuHidden: this.$store.state.appConfig.layout.menu.hidden,
      xml: '',
      payingCompany: '',
      availableBalance: '',
      ruc: '',
      documentType: '',
      documentNumber: '',
      receptionDate: '',
      payingDate: '',
      currency: '',
      payingAmount: '',
      payingTerm: '',
      duesQuantity: '',
      originalAmount: '',
      observation: '',
      frontalImage: '',
      backImage: '',
      payingValues: [],
      status: '',
      requestId: '',
      approveMotive: '',
      rejectMotive: '',
      choice: '',
      receiptStatus: '',
      show: false,
    }
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
    const receiptId = this.$route.params.id
    useJwt.getReceiptDetail({
      email: this.userEmail,
      receiptId,
    }).then(res => {
      this.xml = res.data.receipt.xmlUrl
      this.payingCompany = res.data.receipt.payer.businessName
      this.ruc = res.data.receipt.payer.ruc
      this.availableBalance = res.data.receipt.availableBalance
      this.documentType = res.data.receipt.documentType
      this.documentNumber = res.data.receipt.documentNumber
      this.receptionDate = res.data.receipt.receptionDate
      this.payingDate = res.data.receipt.payingDate
      this.currency = res.data.receipt.currency
      this.payingAmount = res.data.receipt.payingAmount
      this.payingTerm = res.data.receipt.payingTerm
      this.duesQuantity = res.data.receipt.duesQuantity
      this.originalAmount = res.data.receipt.originalAmount
      this.observation = res.data.receipt.observation
      this.frontalImage = res.data.receipt.frontalImageUrl
      this.backImage = res.data.receipt.backImageUrl
      this.payingValues = res.data.receipt.payingValues
      this.status = res.data.receipt.status
      this.receiptStatus = res.data.receipt.receiptStatus ? res.data.receipt.receiptStatus : 'notApproved'
      this.requestId = res.data.requestId
      this.show = this.receiptStatus === 'notApproved'
    })
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  methods: {
    formatterMoney(value) {
      return value.replace(/[^0-9.]/g, '')
    },
    handleReject(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.validateRejectForm()
    },
    handleApprove(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.validateApproveForm()
    },
    choiceConfirmed() {
      const receiptId = this.$route.params.id
      if (this.choice === 'reject') {
        useJwt.updateReceipt({
          email: this.userEmail,
          requestId: this.requestId,
          receiptId,
          action: 'reject',
          data: {
            rejectMotive: this.rejectMotive,
          },
        })
          .then(() => {
            this.$router.replace(`/admin/lista-comprobantes-pago/${this.requestId}`)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    position: 'top-right',
                    title: 'Éxito',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'Se ha rechazado el comprobante',
                  },
                },
                {
                  timeout: 8000,
                })
              })
          })
          .catch(error => {
            console.log('errores', error.response.data.error)
            this.$refs.personalDataForm.setErrors(error.response.data.error)
          })
      } else if (this.choice === 'approve') {
        useJwt.updateReceipt({
          email: this.userEmail,
          requestId: this.requestId,
          receiptId,
          action: 'approve',
          data: {
            approveMotive: this.approveMotive,
          },
        })
          .then(() => {
            this.$router.replace(`/admin/lista-comprobantes-pago/${this.requestId}`)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Éxito',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'Se ha aprobado el comprobante',
                  },
                },
                {
                  timeout: 8000,
                })
              })
          })
          .catch(error => {
            console.log('errores', error.response.data.error)
            this.$refs.personalDataForm.setErrors(error.response.data.error)
          })
      }
    },
    validateRejectForm() {
      this.$refs.rejectForm.validate().then(success => {
        if (success) {
          this.choice = 'reject'
          this.$refs['my-modal'].show()
        }
      })
    },
    validateApproveForm() {
      this.$refs.approveForm.validate().then(success => {
        if (success) {
          this.choice = 'approve'
          this.$refs['my-modal'].show()
        }
      })
    },
    choiceCanceled() {
      this.choice = ''
      this.approveMotive = ''
      this.rejectMotive = ''
    },
  },
}
</script>

<style>

</style>
